export const ROUTES = {
  login: '/',
  main: '/main',
  profile: '/profile',
  notFound: '/404',
  resetPassword: '/reset-password',
  requestResetLinkPassword: '/reset',
  signUp: '/sign-up',
  uiKit: '/uiKit',
  privacy: '/privacy',
  terms: '/terms'
};
