import client, { post } from '../client';
import { setRefreshToken, setToken } from 'utils/tokenUtils';
import { ApiRoutes } from 'constants/apiRoutes';

const AUTH_SERVICE = '/api/auth/login';

export const authenticate = async (login: string, password: string) => {
  return client
    .post(`${AUTH_SERVICE}`, {
      username: login,
      password: password
    })
    .then((response) => {
      setToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      return Promise.resolve();
    })
    .catch(() => Promise.reject());
};

export interface AuthUserDTO {
  username: string;
  password: string;
}

export const authUser = async (formData: AuthUserDTO) => {
  return post({
    url: ApiRoutes.login,
    body: formData
  });
};

export const logoutUser = () => {
  return post({ url: ApiRoutes.logout, body: {} });
};

export const refreshToken = () => {
  return post({ url: ApiRoutes.refresh });
};

// export const refreshToken = async () => {
//     client
//     .post(`${process.env.REACT_APP_BASE_URL}/refresh`, getRefreshToken())
//     .then((response) => {
//       setToken(response.data.access_token);
//       setRefreshToken(response.data.refresh_token);
//     })
//     .catch(() => logout());
// };
