import { UUID } from 'crypto';
import { IPagination } from '../clinics/types';
import { RehabilitationType } from '../patients/types';

export enum DeviceStatusType {
  ACTIVE = 'ACTIVE',
  ASSIGNED = 'ASSIGNED',
  IN_ACTIVE = 'IN_ACTIVE'
}

export interface DevicesState {
  devices: IDevice[];
  pagination: IPagination;
  filter: null | string;
}

export interface IDevice {
  clinicName: string;
  deviceName: string;
  id: string;
  serialNumber: string;
  status: DeviceStatusType;
  type: RehabilitationType;
  isSuspend: boolean;
}

export interface IAddDevice {
  deviceId: string;
  serialNumber: string;
  deviceName: string;
  deviceType: RehabilitationType;
}

export interface DevicesListParams {
  isFullList?: boolean;
  onSuccess?: () => void;
  onFail?: () => void;
  search?: string;
}
