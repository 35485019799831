import { createSlice } from '@reduxjs/toolkit';
import { ClinicDetailState } from './types';

const initialState: ClinicDetailState = {
  pagination: {
    page: 0,
    size: 15,
    total: 0,
    sorting: 'asc'
  }
};

export const clinicDetailSlice = createSlice({
  name: 'clinicsDetail',
  initialState,
  reducers: {
    setDevicePageDetails: (state, { payload }) => {
      state.pagination.page = payload;
    },
    setDeviceSizeDetails: (state, { payload }) => {
      state.pagination.size = payload;
    },
    setDeviceTotalDetails: (state, { payload }) => {
      state.pagination.total = payload;
    },
    toggleClinicsDeviceSortingDetails: (state) => {
      state.pagination.sorting = state.pagination.sorting === 'asc' ? 'desc' : 'asc';
    },
    resetPagination: (state) => {
      state.pagination = {
        page: 0,
        size: 15,
        total: 0,
        sorting: 'asc'
      };
    }
  }
});

export const {
  setDevicePageDetails,
  setDeviceSizeDetails,
  setDeviceTotalDetails,
  toggleClinicsDeviceSortingDetails,
  resetPagination
} = clinicDetailSlice.actions;

export default clinicDetailSlice.reducer;
