const baseApi = '/api';

const clinicsApi = `${baseApi}/clinics`;

const authApi = `${baseApi}/auth`;

const trainingsApi = `${baseApi}/trainings`;

const clinicsTrainingsApi = `${baseApi}/clinics/trainings`;

export const ApiRoutes = {
  clinics: `${clinicsApi}/clinics`,
  devices: `${clinicsApi}/devices`,
  diagnoses: `${clinicsApi}/diagnoses`,
  doctors: `${clinicsApi}/doctors`,
  patients: `${clinicsApi}/patients`,
  profiles: `${clinicsApi}/profiles`,
  login: `${authApi}/login`,
  logout: `${authApi}/logout`,
  refresh: `${authApi}/refresh`,
  plan: `${clinicsTrainingsApi}/plans`,
  scenario: `${trainingsApi}/scenarios`,
  clinicStatistics: `${clinicsApi}/statistics`,
  doctorStatistics: `${clinicsApi}/statistics/doctor`,
  sessions: `${clinicsTrainingsApi}/sessions/patient`,
  exercisesDetails: `${clinicsTrainingsApi}/exercises/patient`
};
