import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from 'constants/apiRoutes';
import { PURGE } from 'redux-persist';
import { RootState } from 'redux/store';

import { deleteEntity, getWithPage, post, put } from 'services/client';
import { IInputs } from 'views/organisms/CreatePhysiciansAccount';
import { IPhysician, PhysiciansState } from './types';

export const getPhysiciansList = async (page: number, size: number, clinicId: string) => {
  const response = await getWithPage<IPhysician[]>({
    url: `${ApiRoutes.doctors}/${clinicId}?page=${page}&size=${size}`
  });
  return response;
};

export const fetchPhysiciansList = createAsyncThunk(
  'doctors/getList',
  async (
    {
      onSuccess,
      onFail,
      clinicId
    }: { onSuccess?: () => void; onFail?: () => void; clinicId: string },
    { getState, dispatch }
  ) => {
    try {
      const {
        physicians: {
          pagination: { page, size }
        }
      } = getState() as RootState;
      const result = await getPhysiciansList(page, size, clinicId);
      onSuccess && onSuccess();
      dispatch(setPhysiciansTotal(result.totalElements));
      return result.data;
    } catch (error) {
      onFail && onFail();
    }
  }
);

export const toggleDoctor = async (data: IInputs) => {
  await put({ url: `${ApiRoutes.doctors}/toggleDoctorEnabledStatus`, body: data });
};

export const resendSignUpMail = async (userId: string) => {
  await post({ url: `${ApiRoutes.doctors}/${userId}/resend-sign-up` });
};

export const deletePhysician = async (id: string, data: IInputs) => {
  await deleteEntity({ url: `${ApiRoutes.doctors}/${id}`, data });
};
export const deleteDemoPhysician = async (id: string) => {
  await deleteEntity({ url: `${ApiRoutes.doctors}/demo/${id}` });
};
export const createPhysician = async (data: IInputs) => {
  await post({ url: ApiRoutes.doctors, body: data });
};

export const setPhysicianSuperDoctor = async (id: string) => {
  await post({ url: `${ApiRoutes.doctors}/${id}/super-doctor` });
};

export const removePhysicianSuperDoctor = async (id: string) => {
  await deleteEntity({ url: `${ApiRoutes.doctors}/${id}/super-doctor` });
};

const initialState: PhysiciansState = {
  physicians: [],
  pagination: {
    page: 0,
    size: 15,
    total: 0
  }
};

export const physiciansSlice = createSlice({
  name: 'physicians',
  initialState,
  reducers: {
    setPhysicians: (state, { payload }) => {
      state.physicians = payload;
    },
    setPhysiciansPage: (state, { payload }) => {
      state.pagination.page = payload;
    },
    setPhysiciansSize: (state, { payload }) => {
      state.pagination.size = payload;
    },
    setPhysiciansTotal: (state, { payload }) => {
      state.pagination.total = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhysiciansList.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.physicians = payload as IPhysician[];
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const { setPhysicians, setPhysiciansPage, setPhysiciansSize, setPhysiciansTotal } =
  physiciansSlice.actions;

export default physiciansSlice.reducer;
