import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import menuReducer from './reducers/menu';
import clinicsReducer from './reducers/clinics';
import patientsReducer from './reducers/patients';
import physiciansReducer from './reducers/physicians';
import devicesReducer from './reducers/devices';
import userReducer from './reducers/user';
import scenariosReducer from './reducers/scenarios';
import appReducer from './reducers/app';
import statisticsReducer from './reducers/statistics';
import clinicDetailReducer from './reducers/clinics/clinicDetails';
import physiciansForDashboardReducer from './reducers/physicians/physiciansForDashboard';

const persistConfig = {
  key: 'root',
  storage: storage
};

const rootReducer = combineReducers({
  menu: menuReducer,
  clinics: clinicsReducer,
  clinicDetail: clinicDetailReducer,
  patients: patientsReducer,
  physicians: physiciansReducer,
  physiciansForDashboard: physiciansForDashboardReducer,
  devices: devicesReducer,
  user: userReducer,
  scenarios: scenariosReducer,
  app: appReducer,
  statistics: statisticsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
