import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from 'redux/reducers/user/selectors';

import { localStorageManager } from 'services/localStorage';

interface Props {
  children: React.ReactElement;
}

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const accessToken = localStorageManager.getAccessToken();
  const user = useSelector(selectUser);

  return accessToken && user ? <>{children}</> : <Navigate to="/" />;
};
