import { SxProps, Theme } from '@mui/material/styles';
import styled from 'styled-components';

export const Div = styled.div<{ width: number; height: number; sx?: SxProps<Theme> }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  ${({ sx }) => ({ ...(sx && { ...sx }) })}
  > img {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
  }
`;
