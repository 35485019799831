import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { RootState } from 'redux/store';
import { getPhysiciansList } from '..';
import { IPhysician, PhysiciansState } from '../types';

export const fetchPhysicians = createAsyncThunk(
  'doctors/getList',
  async (
    {
      onSuccess,
      onFail,
      clinicId
    }: { onSuccess?: () => void; onFail?: () => void; clinicId: string },
    { getState, dispatch }
  ) => {
    try {
      const {
        physiciansForDashboard: {
          pagination: { page, size }
        }
      } = getState() as RootState;
      const result = await getPhysiciansList(page, size, clinicId);
      onSuccess && onSuccess();
      dispatch(setPhysiciansTotal(result.totalElements));
      return result.data;
    } catch (error) {
      onFail && onFail();
    }
  }
);

const initialState: PhysiciansState = {
  physicians: [],
  pagination: {
    page: 0,
    size: 15,
    total: 0
  }
};

export const physiciansForDashboardSlice = createSlice({
  name: 'physiciansForDashboard',
  initialState,
  reducers: {
    setPhysicians: (state, { payload }) => {
      state.physicians = payload;
    },
    setPhysiciansPage: (state, { payload }) => {
      state.pagination.page = payload;
    },
    setPhysiciansSize: (state, { payload }) => {
      state.pagination.size = payload;
    },
    setPhysiciansTotal: (state, { payload }) => {
      state.pagination.total = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhysicians.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.physicians = payload as IPhysician[];
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const { setPhysicians, setPhysiciansPage, setPhysiciansSize, setPhysiciansTotal } =
  physiciansForDashboardSlice.actions;

export default physiciansForDashboardSlice.reducer;
