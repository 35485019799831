import styled from 'styled-components';
import { Snackbar } from '@mui/material';

export const StyledSnackbar = styled(Snackbar)<{ color: string }>`
  .MuiPaper-root {
    background-color: ${({ color }) => color};
    display: flex;

    .MuiSnackbarContent-message {
      text-align: center;
    }
  }
`;
