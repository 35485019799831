export enum UserRoles {
  PlATFORM_ADMIN = 'platform-admin',
  CLINIC_ADMIN = 'clinic-admin', // only way to create to create doctor
  CLINIC_ADMIN_DEMO = 'clinic-admin-demo',
  SUPER_DOCTOR = 'super-doctor',
  CLINIC_DOCTOR = 'clinic-doctor',
  CLINIC_DOCTOR_DEMO = 'clinic-doctor-demo'
}

export interface Base {
  onSuccess: () => void;
  onFail?: (error?: any) => void;
}

export interface Pagination {
  page: number;
  size: number;
  setPagination: (total: number) => void;
}
