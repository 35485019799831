import { Typography, TypographyProps } from '@mui/material';
import { Media } from 'enums';
import styled, { css } from 'styled-components';

export type TextTypeProps = {
  kind: 'h1' | 'h2' | 'h3' | 'h4' | 'button' | 'buttonSmall' | 'body' | 'bodySmall' | 'caption';
  margin?: string;
  width?: number;
};

export const TextOverflow = styled(Typography)<TypographyProps & TextTypeProps>`
  color: ${({ color, theme }) => color ?? theme.colors.mainText};
  margin: ${({ margin }) => margin ?? 0};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  &:hover {
    cursor: ${({ kind }) => (kind === 'button' ? 'pointer' : 'auto')};
  }
  ${({ kind, theme }) => {
    switch (kind) {
      case 'h1':
        return css`
          font-weight: ${theme.typography.h1.fontWeight};
          font-size: ${theme.typography.h1.fontSize};
          line-height: ${theme.typography.h1.lineHeight};
        `;
      case 'h2':
        return css`
          font-weight: ${theme.typography.h2.fontWeight};
          font-size: ${theme.typography.h2.fontSize};
          line-height: ${theme.typography.h2.lineHeight};
        `;
      case 'h3':
        return css`
          font-weight: ${theme.typography.h3.fontWeight};
          font-size: ${theme.typography.h3.fontSize};
          line-height: ${theme.typography.h3.lineHeight};
        `;
      case 'h4':
        return css`
          font-weight: ${theme.typography.h4.fontWeight};
          font-size: ${theme.typography.h4.fontSize};
          line-height: ${theme.typography.h4.lineHeight};
        `;
      case 'button':
        return css`
          font-weight: ${theme.typography.button.fontWeight};
          font-size: ${theme.typography.button.fontSize};
          line-height: ${theme.typography.button.lineHeight};
        `;
      case 'body':
        return css`
          font-weight: ${theme.typography.body.fontWeight};
          font-size: ${theme.typography.body.fontSize};
          line-height: ${theme.typography.body.lineHeight};
        `;
      case 'buttonSmall':
        return css`
          font-weight: ${theme.typography.buttonSmall.fontWeight};
          font-size: ${theme.typography.buttonSmall.fontSize};
          line-height: ${theme.typography.buttonSmall.lineHeight};
        `;
      case 'bodySmall':
        return css`
          font-weight: ${theme.typography.bodySmall.fontWeight};
          font-size: ${theme.typography.bodySmall.fontSize};
          line-height: ${theme.typography.bodySmall.lineHeight};
        `;
      case 'caption':
        return css`
          font-weight: ${theme.typography.caption.fontWeight};
          font-size: ${theme.typography.caption.fontSize};
          line-height: ${theme.typography.caption.lineHeight};
        `;
      default:
        return '';
    }
  }};
`;

export const Text = styled.p<TextTypeProps>`
  color: ${({ color, theme }) => color ?? theme.colors.mainText};
  margin: ${({ margin }) => margin ?? 0};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  &:hover {
    cursor: ${({ kind }) => (kind === 'button' ? 'pointer' : 'auto')};
  }
  ${({ kind, theme }) => {
    switch (kind) {
      case 'h1':
        return css`
          font-weight: ${theme.typography.h1.fontWeight};
          font-size: ${theme.typography.h1.fontSize};
          line-height: ${theme.typography.h1.lineHeight};
        `;
      case 'h2':
        return css`
          font-weight: ${theme.typography.h2.fontWeight};
          font-size: ${theme.typography.h2.fontSize};
          line-height: ${theme.typography.h2.lineHeight};
        `;
      case 'h3':
        return css`
          font-weight: ${theme.typography.h3.fontWeight};
          font-size: ${theme.typography.h3.fontSize};
          line-height: ${theme.typography.h3.lineHeight};
        `;
      case 'h4':
        return css`
          font-weight: ${theme.typography.h4.fontWeight};
          font-size: ${theme.typography.h4.fontSize};
          line-height: ${theme.typography.h4.lineHeight};
        `;
      case 'button':
        return css`
          font-weight: ${theme.typography.button.fontWeight};
          font-size: ${theme.typography.button.fontSize};
          line-height: ${theme.typography.button.lineHeight};
          @media ${Media.tablet} {
            font-weight: ${({ theme }) => theme.typography.buttonSmall.fontWeight};
            font-size: ${({ theme }) => theme.typography.buttonSmall.fontSize};
            line-height: ${({ theme }) => theme.typography.buttonSmall.lineHeight};
            word-break: break-all;
            text-align: left;
          }
          :hover {
            cursor: pointer;
          }
        `;
      case 'body':
        return css`
          font-weight: ${theme.typography.body.fontWeight};
          font-size: ${theme.typography.body.fontSize};
          line-height: ${theme.typography.body.lineHeight};
        `;
      case 'buttonSmall':
        return css`
          font-weight: ${theme.typography.buttonSmall.fontWeight};
          font-size: ${theme.typography.buttonSmall.fontSize};
          line-height: ${theme.typography.buttonSmall.lineHeight};
          :hover {
            cursor: pointer;
          }
        `;
      case 'bodySmall':
        return css`
          font-weight: ${theme.typography.bodySmall.fontWeight};
          font-size: ${theme.typography.bodySmall.fontSize};
          line-height: ${theme.typography.bodySmall.lineHeight};
        `;
      case 'caption':
        return css`
          font-weight: ${theme.typography.caption.fontWeight};
          font-size: ${theme.typography.caption.fontSize};
          line-height: ${theme.typography.caption.lineHeight};
        `;
      default:
        return '';
    }
  }};
`;
