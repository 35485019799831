import { SxProps, Theme } from '@mui/material/styles';
import { Div } from './Icon.styles';

interface IconProps {
  src: string;
  width: number;
  height: number;
  sx?: SxProps<Theme>;
}
export const Icon = ({ src, ...restProps }: IconProps) => {
  return (
    <Div {...restProps}>
      <img src={src} />
    </Div>
  );
};
