import { FC, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { PrivateRoute } from './components/PrivateRoot';

const AuthPage = lazy(() =>
  import('../Auth').then(({ Auth }) => ({
    default: Auth
  }))
);

const MainPage = lazy(() =>
  import('../Main').then(({ MainPage }) => ({
    default: MainPage
  }))
);

const RequestResetLinkPage = lazy(() =>
  import('../RequestResetLink').then(({ RequestResetLinkPage }) => ({
    default: RequestResetLinkPage
  }))
);

const SignUpPage = lazy(() =>
  import('../SignUp').then(({ SignUpPage }) => ({
    default: SignUpPage
  }))
);

const ResetPasswordPage = lazy(() =>
  import('../ResetPassword').then(({ ResetPasswordPage }) => ({
    default: ResetPasswordPage
  }))
);

const UIKitPage = lazy(() =>
  import('../UIKit').then(({ UiKitPage }) => ({
    default: UiKitPage
  }))
);

const Privacy = lazy(() =>
  import('../Privacy').then(({ Privacy }) => ({
    default: Privacy
  }))
);

const Terms = lazy(() =>
  import('../Terms').then(({ Terms }) => ({
    default: Terms
  }))
);

export const PageRoot: FC = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={ROUTES.login} element={<AuthPage />} />
        <Route
          path={ROUTES.main}
          element={
            <PrivateRoute>
              <MainPage />
            </PrivateRoute>
          }
        />
        <Route path={ROUTES.requestResetLinkPassword} element={<RequestResetLinkPage />} />
        <Route path={ROUTES.resetPassword} element={<ResetPasswordPage />} />
        {/* <Route path={ROUTES.signUp} element={<SignUpPage />} /> */}
        <Route path={ROUTES.privacy} element={<Privacy />} />
        <Route path={ROUTES.terms} element={<Terms />} />
        <Route
          path={ROUTES.uiKit}
          element={
            <PrivateRoute>
              <UIKitPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};
