import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html, body, #root {
    min-height: 100vh;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    color: #141453;
    -webkit-tap-highlight-color: transparent;
  }

  p {
    font-family: 'PT Sans';
    margin: 0;
    padding: 0;
  }

  div {
    box-sizing: border-box;
  }
`;
