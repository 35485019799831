export enum Colors {
  tagBlue = '#EBF2FF',
  tagGreen = '#EAF8F6',
  mainText = '#141453',
  lineHover = 'rgba(229, 229, 242, 0.7)',
  lightViolet = '#C4C4EA',
  violet = '#9999E0',
  actionViolet = '#3E23A1',
  berry = '#D2576E',
  alert = '#9F4354',
  background = '#F8F8FA',
  borders = '#E5E5F2',
  disabled = '#9293AF',
  labels = '#6B6B90',
  darkGrey = '#5A5B84',
  grayedOut = '#919099',
  success = '#00838B',
  white = '#FFF',
  profileBG = '#E7E7E7',
  black = '#333',
  yellow = '#F2BB3F',
  aftercare = '#4D8CDA',
  stationary = '#F17189'
}

export enum Media {
  mobile = '(min-width: 360px)',
  tablet = '(min-width: 361px)',
  notebook = '(min-width: 1366px)',
  desktop = '(min-width: 1920px)'
}
