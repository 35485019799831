import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from 'constants/apiRoutes';
import { PURGE } from 'redux-persist';
import { Base } from 'redux/types';

import { get } from 'services/client';
import { fetchPhysiciansList } from '../physicians';
import {
  GetClinicStatisticsParams,
  IClinicStatistic,
  IDoctorStatistics,
  StatisticsState
} from './types';

export const fetchClinicStatistics = createAsyncThunk(
  'statistics/getClinicStats',
  async ({ onSuccess, onFail, clinicId }: GetClinicStatisticsParams, { dispatch }) => {
    try {
      const response = await get({ url: ApiRoutes.clinicStatistics });
      dispatch(fetchPhysiciansList({ clinicId: `${clinicId}` }));
      onSuccess();
      return response;
    } catch (error) {
      onFail && onFail();
    }
  }
);

export const fetchDoctorStatistics = createAsyncThunk(
  'statistics/getDoctorStats',
  async ({ onSuccess, onFail }: Base) => {
    try {
      const response = await get({ url: ApiRoutes.doctorStatistics });
      onSuccess();
      return response;
    } catch (error) {
      onFail && onFail();
    }
  }
);

const initialState: StatisticsState = {
  clinicStats: null,
  doctorStats: null
};

export const statisticsSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClinicStatistics.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.clinicStats = payload as IClinicStatistic;
    });
    builder.addCase(fetchDoctorStatistics.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.doctorStats = payload as IDoctorStatistics;
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export default statisticsSlice.reducer;
