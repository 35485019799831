import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from 'constants/apiRoutes';
import { PURGE } from 'redux-persist';
import { RootState } from 'redux/store';

import { deleteEntity, get, getWithPage, post, put } from 'services/client';
import { IInputs } from 'views/organisms/AddNewClinic';

import {
  ClinicListParams,
  ClinicsState,
  GetClinicInfoParams,
  GetClinicListParams,
  IAddAdmin,
  IClinic
} from './types';

const getClinicsList = async (page: number, size: number, asc: boolean) => {
  const response = await getWithPage<IClinic[]>({
    url: `${ApiRoutes.clinics}?page=${page}&size=${size}&asc=${asc}`
  });
  return response;
};

export const getClinicsListByName = async (name: string) => {
  const result = await getWithPage({
    url: `${ApiRoutes.clinics}/search?name=${name}`
  });
  return result;
};

export const fetchClinicsList = createAsyncThunk(
  'clinics/getList',
  async ({ onSuccess, onFail, search }: ClinicListParams, { getState, dispatch }) => {
    try {
      const {
        clinics: {
          pagination: { page, size, sorting }
        }
      } = getState() as RootState;
      if (search) {
        const result = await getClinicsListByName(search);
        onSuccess && onSuccess();
        dispatch(setClinics(result));
        onSuccess && onSuccess();
      } else {
        // returns all clinics in an ascending order
        const result = await getClinicsList(page, size, sorting === 'asc');
        dispatch(setTotal(result.totalElements));
        onSuccess && onSuccess();
        return result.data;
      }
    } catch (error) {
      onFail && onFail();
    }
  }
);

export const deleteClinic = async (id: string) => {
  await deleteEntity({ url: `${ApiRoutes.clinics}/${id}` });
};

export const createClinic = async (data: IInputs) => {
  await post({ url: ApiRoutes.clinics, body: data });
};

export const updateClinic = async (data: IInputs, id: string) => {
  await put({ url: `${ApiRoutes.clinics}/${id}`, body: data });
};

export const getClinicDevices = createAsyncThunk(
  'clinics/getClinicsDevices',
  async ({ id, onSuccess }: GetClinicInfoParams, { dispatch }) => {
    const res: any = await get({ url: `${ApiRoutes.clinics}/${id}/devices` });
    dispatch(setClinicDevices(res));
    onSuccess && onSuccess();
  }
);
export const getClinicDevicesList = createAsyncThunk(
  'clinics/getClinicsDevicesList',
  async ({ id, onSuccess, page, size }: GetClinicListParams, { dispatch }) => {
    const res: any = await get({
      url: `${ApiRoutes.clinics}/${id}/devices?page=${page}&size=${size}`
    });
    dispatch(setClinicDevices(res));
    onSuccess && onSuccess();
  }
);

export const addDeviceToClinic = async (id: string, data: { devices: string[] }) => {
  await post({ url: `${ApiRoutes.clinics}/${id}/devices`, body: data });
};

export const deleteDevicesFromClinic = async (id: string, data: { devices: string[] }) => {
  await deleteEntity({ url: `${ApiRoutes.clinics}/${parseInt(id)}/devices`, data });
};

export const createAdminForClinic = async (id: string, data: IAddAdmin) => {
  await post({ url: `${ApiRoutes.doctors}/${id}`, body: data });
};

export const updateAdminForClinic = async (id: string, data: IAddAdmin) => {
  await put({ url: `${ApiRoutes.doctors}/${id}`, body: data });
};

export const deleteAdminForClinic = async (previousAdminId: string) => {
  await deleteEntity({ url: `${ApiRoutes.doctors}/${previousAdminId}/admin` });
};

const initialState: ClinicsState = {
  tabData: {
    id: '',
    name: '',
    address: '',
    numberOfDevices: 0,
    numberOfPatients: 0,
    adminFullName: '',
    devices: [],
    adminId: '',
    adminEmail: '',
    adminEmailStatus: false,
    clinicType: ''
  },
  clinics: [],
  pagination: {
    page: 0,
    size: 15,
    total: 0,
    sorting: 'asc'
  }
};

export const clinicsSlice = createSlice({
  name: 'clinics',
  initialState,
  reducers: {
    setTabData: (state, { payload }) => {
      state.tabData = payload;
    },
    setClinics: (state, { payload }) => {
      state.clinics = payload;
    },
    setClinicDevices: (state, { payload }) => {
      state.tabData.devices = payload;
    },
    setPage: (state, { payload }) => {
      state.pagination.page = payload;
    },
    setSize: (state, { payload }) => {
      state.pagination.size = payload;
    },
    setTotal: (state, { payload }) => {
      state.pagination.total = payload;
    },
    setCleanClinics: (state) => {
      state.clinics = initialState.clinics;
      state.tabData = initialState.tabData;
    },
    toggleClinicsSorting: (state) => {
      state.pagination.sorting = state.pagination.sorting === 'asc' ? 'desc' : 'asc';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClinicsList.fulfilled, (state, { payload }) => {
      if (!payload) return;

      if (state.tabData.id && Array.isArray(payload)) {
        const updatedClinic = payload.find((item: IClinic) => item.id === state.tabData.id);
        if (updatedClinic) {
          state.tabData = {
            ...state.tabData,
            ...updatedClinic
          };
        }
      }

      state.clinics = payload as IClinic[];
    });
    builder.addCase(fetchClinicsList.rejected, (state) => {
      state.clinics = [];
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const {
  setTabData,
  setClinics,
  setClinicDevices,
  setPage,
  setSize,
  setTotal,
  setCleanClinics,
  toggleClinicsSorting
} = clinicsSlice.actions;

export default clinicsSlice.reducer;
