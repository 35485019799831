import { BrowserRouter, HashRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Snackbar } from 'views/atoms/Snackbar';
import { PageRoot } from 'views/pages/PageRoot';

const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`;

const App = () => {
  return (
    <HashRouter>
      <AppStyled>
        <PageRoot />
        <Snackbar />
      </AppStyled>
    </HashRouter>
  );
};

export default App;
