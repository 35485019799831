import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from 'constants/apiRoutes';
import { PURGE } from 'redux-persist';
import { Base } from 'redux/types';

import { deleteEntity, get, getWithPage, post, put } from 'services/client';
import {
  AddAftercarePlanParams,
  AddNewPatientParams,
  AddStationaryPlanParams,
  DeletePatientParams,
  GetPatientSessionParams,
  IDiagnose,
  ISessionExercise,
  IPatient,
  IPatientUpdateData,
  ISession,
  PatientsState,
  ReassignOthersPatientParams,
  ReassignOwnPatientParams,
  RehabilitationType,
  UpdateAftercarePlanParams,
  UpdateStationaryPlanParams,
  ISessionUpdate
} from './types';
import { UUID } from 'crypto';

const initialState: PatientsState = {
  patients: [],
  personalPatients: [],
  archivedPatients: [],
  selectedPatient: null,
  selectedPatientDetails: null,
  selectedPatientPlan: [],
  selectedPatientDevice: '',
  selectedPatientSession: [],
  selectedUnCompletedSession: [],
  selectedPatientExercises: [],
  selectedPatientSessionsAmount: null,
  isDeviceRequested: false,
  loading: false,
  diagnoses: []
};

export const fetchPersonalPatientsList = createAsyncThunk(
  'patients/getPersonalList',
  async (id: string) => {
    const response = await get({ url: `${ApiRoutes.patients}/${id}` });
    return response;
  }
);

export const fetchPatientsList = createAsyncThunk('patients/getList', async (id: string) => {
  const response = await get({ url: `${ApiRoutes.patients}` });
  return {
    data: response,
    id
  };
});

export const fetchPatientsExercises = createAsyncThunk(
  'patients/getPatientExercises',
  async (id: UUID, { dispatch }) => {
    dispatch(toggleLoading());
    const response = await get<{
      exerciseIds: string[];
      amountOfSessions: null | number;
      requested: boolean;
      deviceName: string;
    }>({
      url: `${ApiRoutes.plan}/${id}`
    }).catch(() => ({ exerciseIds: [], amountOfSessions: null, requested: false, deviceName: '' }));
    dispatch(toggleLoading());
    return response;
  }
);

// very confusing naming! You can't delete an ongoing session. You can only delete a stationary plan
export const deleteOngoingStationarySession = async (id: string) => {
  await deleteEntity({ url: `${ApiRoutes.plan}/${id}` });
};

export const deleteAftercarePlan = async (patient_uuid: UUID) => {
  await deleteEntity({ url: `${ApiRoutes.plan}/aftercare/${patient_uuid}` });
};

export const fetchPatientsExercisesDetails = createAsyncThunk(
  'patients/getPatientsExercisesDetails',
  async ({ id }: GetPatientSessionParams, { dispatch }) => {
    dispatch(toggleLoading());
    const response = await get({ url: `${ApiRoutes.exercisesDetails}/${id}` });
    dispatch(toggleLoading());
    return response;
  }
);

export const fetchDiagnoses = createAsyncThunk('patients/getDiagnoses', async () => {
  const response = await get({ url: ApiRoutes.diagnoses });
  return response;
});

export const fetchPatientDetails = createAsyncThunk(
  'patients/getPatientDetails',
  async (uuid: UUID, { dispatch }) => {
    const response = await get({ url: `${ApiRoutes.patients}/${uuid}/info` });
    dispatch(setPatientDetails(response));
  }
);

export const assignDeviceToPatient = createAsyncThunk(
  'patients/assignDevice',
  async ({ uuid, deviceId }: { uuid: UUID; deviceId: string }, { dispatch }) => {
    await post({ url: `${ApiRoutes.patients}/${uuid}/${deviceId}` });
    dispatch(fetchPatientDetails(uuid));
  }
);

export const changePatientDevice = createAsyncThunk(
  'patients/assignDevice',
  async ({ id, deviceId }: { id: UUID; deviceId: string }, { dispatch }) => {
    await put({ url: `${ApiRoutes.patients}/${id}/${deviceId}` });
    dispatch(fetchPatientDetails(id));
  }
);

export const reassignOwnPatient = createAsyncThunk(
  'patients/reassignOwnPatient',
  async ({ data, doctorId, onSuccess }: ReassignOwnPatientParams, { dispatch }) => {
    await put({ url: `${ApiRoutes.patients}/assign-own`, body: data });
    dispatch(fetchPersonalPatientsList(doctorId));
    onSuccess();
  }
);

export const reassignOthersPatient = createAsyncThunk(
  'patients/reassignOthersPatient',
  async ({ data, onSuccess, doctorId }: ReassignOthersPatientParams, { dispatch }) => {
    await put({ url: `${ApiRoutes.patients}/assign`, body: data });
    dispatch(fetchPatientsList(doctorId));
    onSuccess();
  }
);

export const updatePatient = createAsyncThunk(
  'patients/updatePatients',
  async (patient: Partial<IPatientUpdateData>, { dispatch }) => {
    dispatch(toggleLoading());
    await put({ url: ApiRoutes.patients, body: patient });
    if (patient.patientUuid) {
      dispatch(fetchPatientDetails(patient.patientUuid));
    }
    dispatch(toggleLoading());
  }
);

export const addNewPatient = createAsyncThunk(
  'patients/addNewPatient',
  async ({ data, doctorId, onSuccess, onFail }: AddNewPatientParams, { dispatch }) => {
    try {
      await post({ url: ApiRoutes.patients, body: data });
      dispatch(fetchPersonalPatientsList(doctorId));
      onSuccess();
    } catch (error) {
      onFail && onFail(error);
    }
  }
);

export const addStationaryPlan = createAsyncThunk(
  'patients/addStationaryPlan',
  async ({ data, id, onSuccess, onFail }: AddStationaryPlanParams, { dispatch }) => {
    try {
      await post({ url: `${ApiRoutes.plan}/${id}/stationary`, body: data });
      await put({ url: `${ApiRoutes.devices}/status/${data.deviceId}/ASSIGNED` });
      dispatch(fetchPatientsExercises(id));
      dispatch(fetchPatientDetails(id));
      onSuccess();
    } catch (error) {
      onFail && onFail(error);
    }
  }
);

export const addAftercarePlan = createAsyncThunk(
  'patients/addAftercarePlan',
  async ({ data, id, onSuccess }: AddAftercarePlanParams, { dispatch }) => {
    await put({
      url: ApiRoutes.patients,
      body: { patientUuid: id, placingType: RehabilitationType.Aftercare }
    });
    await post({ url: `${ApiRoutes.plan}/${id}/aftercare`, body: data });
    await put({ url: `${ApiRoutes.devices}/status/${data.deviceId}/ASSIGNED` });
    dispatch(fetchPatientsExercises(id));
    dispatch(fetchPatientDetails(id));
    dispatch(movePatient(id));
    onSuccess();
  }
);

export const updateStationaryPlan = createAsyncThunk(
  'patients/updateStationaryPlan',
  async ({ data, id, onSuccess }: UpdateStationaryPlanParams, { dispatch }) => {
    await put({ url: `${ApiRoutes.plan}/${id}/stationary`, body: data });
    dispatch(fetchPatientsExercises(id));
    onSuccess();
  }
);

export const updateAftercarePlan = createAsyncThunk(
  'patients/updateStationaryPlan',
  async ({ data, patientUuid, onSuccess, onFail }: UpdateAftercarePlanParams, { dispatch }) => {
    try {
      await put({ url: `${ApiRoutes.plan}/${patientUuid}/aftercare`, body: data });
      dispatch(fetchPatientsExercises(patientUuid));
      onSuccess();
    } catch (e) {
      onFail && onFail();
    }
  }
);

export const deletePatient = createAsyncThunk(
  'patients/deletePatient',
  async ({ id, doctorId, onSuccess }: DeletePatientParams, { dispatch }) => {
    await deleteEntity({ url: `${ApiRoutes.patients}/${id}` });
    dispatch(fetchPersonalPatientsList(doctorId));
    dispatch(setSelectedPatient(null));
    onSuccess();
  }
);

export const deactivatePatient = createAsyncThunk(
  'patients/deactivate',
  async ({ id, doctorId, onSuccess, onFail }: DeletePatientParams, { dispatch }) => {
    try {
      await put({ url: `${ApiRoutes.patients}/${id}/status/INACTIVE` });
      dispatch(fetchPersonalPatientsList(doctorId));
      dispatch(fetchPatientsList(doctorId));
      dispatch(setSelectedPatient(null));
      onSuccess();
    } catch (err) {
      onFail && onFail();
    }
  }
);

export const activatePatient = createAsyncThunk(
  'patients/deactivate',
  async ({ id, doctorId, onSuccess }: DeletePatientParams, { dispatch }) => {
    await put({ url: `${ApiRoutes.patients}/${id}/status/ACTIVE` });
    dispatch(fetchPersonalPatientsList(doctorId));
    dispatch(fetchPatientsList(doctorId));
    dispatch(setSelectedPatient(null));
    onSuccess();
  }
);

export const getPatientSessions = createAsyncThunk(
  'patients/getSessions',
  async ({ id }: GetPatientSessionParams) => {
    const response = await get({ url: `${ApiRoutes.sessions}/${id}?completed=true` });
    return response;
  }
);
export const getUnCompletedSessions = createAsyncThunk(
  'patients/getUnCompletedSessions',
  async ({ id }: GetPatientSessionParams) => {
    const response = await get({ url: `${ApiRoutes.sessions}/${id}?completed=false` });
    return response;
  }
);

export const increaseAftercareSessions = createAsyncThunk(
  'patients/increaseSessions',
  async (
    { data, id, onSuccess, onFail }: { data: ISessionUpdate; id: UUID } & Base,
    { dispatch }
  ) => {
    try {
      await put({
        url: `${ApiRoutes.plan}/${id}/aftercare`,
        body: data
      });
      dispatch(fetchPatientsExercises(id));
      onSuccess();
    } catch (error) {
      onFail && onFail();
    }
  }
);

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setSelectedPatient: (state, { payload }) => {
      state.selectedPatient = payload;
    },
    setPatients: (state, { payload }) => {
      state.patients = payload;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    setPatientDetails: (state, { payload }) => {
      state.selectedPatientDetails = payload;
    },
    movePatient: (state, { payload }) => {
      state.personalPatients = state.personalPatients.map((patient) => {
        if (patient.patientUuid === payload) {
          return {
            ...patient,
            placingType: RehabilitationType.Aftercare
          };
        }

        return patient;
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientsList.fulfilled, (state, { payload }) => {
      if (!payload.data) {
        state.patients = [];
        state.personalPatients = [];
        state.selectedPatient = null;
        state.selectedPatientDetails = null;
        state.selectedPatientPlan = [];
        return;
      }

      if (Array.isArray(payload.data)) {
        const personal: IPatient[] = [];
        const all: IPatient[] = [];
        const archived: IPatient[] = [];

        payload.data.forEach((patient: IPatient) => {
          if (patient.status === 'INACTIVE') {
            archived.push(patient);
            return;
          }

          if (patient.doctorId === payload.id) {
            personal.push(patient);
          } else {
            all.push(patient);
          }
          if (patient.patientUuid === state.selectedPatient?.patientUuid) {
            state.selectedPatient = patient;
          }
        });

        state.patients = all;
        state.personalPatients = personal;
        state.archivedPatients = archived;
      }
    });
    builder.addCase(getPatientSessions.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        state.selectedPatientSession = payload as ISession[];
        return;
      }

      state.selectedPatientSession = [];
    });
    builder.addCase(getPatientSessions.rejected, (state) => {
      state.selectedPatientSession = [];
    });
    builder.addCase(getUnCompletedSessions.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        state.selectedUnCompletedSession = payload as ISession[];
        return;
      }

      state.selectedPatientSession = [];
    });
    builder.addCase(getUnCompletedSessions.rejected, (state) => {
      state.selectedUnCompletedSession = [];
    });
    builder.addCase(fetchPersonalPatientsList.fulfilled, (state, { payload }) => {
      if (!payload) {
        state.patients = [];
        state.personalPatients = [];
        state.selectedPatient = null;
        state.selectedPatientDetails = null;
        state.selectedPatientExercises = [];
        return;
      }

      if (state.selectedPatient && Array.isArray(payload)) {
        const updatedPatient = payload.find(
          (item: IPatient) => item.patientUuid === state.selectedPatient?.patientUuid
        );

        if (updatedPatient) {
          state.selectedPatient = updatedPatient;
        }
      }

      if (Array.isArray(payload)) {
        const personal: IPatient[] = [];
        const archived: IPatient[] = [];

        payload.forEach((patient: IPatient) => {
          if (patient.status === 'INACTIVE') {
            archived.push(patient);
            return;
          }

          personal.push(patient);
        });

        state.personalPatients = personal;
        state.archivedPatients = archived;
      }
    });
    builder.addCase(fetchPatientsExercisesDetails.fulfilled, (state, { payload }) => {
      if (Array.isArray(payload) && payload.length) {
        state.selectedPatientExercises = payload as ISessionExercise[];
      }
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(fetchPatientsExercisesDetails.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchPatientsExercises.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePatient.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchPatientsExercises.fulfilled, (state, { payload }) => {
      state.selectedPatientPlan = payload.exerciseIds;
      state.selectedPatientSessionsAmount = payload.amountOfSessions;
      state.isDeviceRequested = payload.requested;
      state.selectedPatientDevice = payload.deviceName;
    });
    builder.addCase(fetchDiagnoses.fulfilled, (state, { payload }) => {
      state.diagnoses = payload as IDiagnose[];
    });
  }
});

export const { setSelectedPatient, setPatients, toggleLoading, setPatientDetails, movePatient } =
  patientsSlice.actions;

export default patientsSlice.reducer;
