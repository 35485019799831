import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { MenuState } from './types';

const initialState: MenuState = {
  activeTab: 'dashboard'
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveMenuTab: (state, { payload }) => {
      state.activeTab = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const { setActiveMenuTab } = menuSlice.actions;

export default menuSlice.reducer;
