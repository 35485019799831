import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from 'constants/apiRoutes';
import { PURGE } from 'redux-persist';
import { RootState } from 'redux/store';

import { deleteEntity, get, getWithPage, post, put } from 'services/client';
import { RehabilitationType } from '../patients/types';
import { DevicesListParams, DevicesState, DeviceStatusType, IAddDevice, IDevice } from './types';

const deviceStatusFilters = [
  DeviceStatusType.ACTIVE,
  DeviceStatusType.ASSIGNED,
  DeviceStatusType.IN_ACTIVE
];

const deviceTypeFilters = [RehabilitationType.Aftercare, RehabilitationType.Stationary];

const getCorrectDeviceFilter = (filter: string | null) => {
  if (filter && deviceStatusFilters.includes(filter as DeviceStatusType)) {
    return `&status=${filter}`;
  } else if (filter && deviceTypeFilters.includes(filter as RehabilitationType)) {
    return `&type=${filter}`;
  }

  return '';
};

const getDevicesList = async (
  page: number,
  size: number,
  isFullList: boolean,
  filter: string | null,
  asc: boolean
) => {
  if (isFullList) {
    const filterParam = getCorrectDeviceFilter(filter);
    const response = await getWithPage<IDevice[]>({
      url: `${ApiRoutes.devices}?page=${page}&size=${size}&asc=${asc}${filterParam}`
    });
    return response;
  }

  const response = await getWithPage<IDevice[]>({
    url: `${ApiRoutes.devices}?page=${page}&size=${size}&suspended=false`
  });
  return response;
};

export const fetchDevicesList = createAsyncThunk(
  'devices/getList',
  async (
    { onSuccess, onFail, isFullList = false, search }: DevicesListParams,
    { getState, dispatch }
  ) => {
    try {
      const {
        devices: {
          pagination: { page, size, sorting },
          filter
        }
      } = getState() as RootState;
      if (search) {
        const response = await getFullDevicesListByName(search, filter);
        dispatch(setDevices(response));
        onSuccess && onSuccess();
      } else {
        const response = await getDevicesList(page, size, isFullList, filter, sorting === 'asc');
        dispatch(setDevicesTotal(response.totalElements));
        onSuccess && onSuccess();
        return response.data;
      }
    } catch (error) {
      dispatch(setDevices([]));
      onFail && onFail();
    }
  }
);

export const getInactiveDevicesList = async (page: number) => {
  const response = await getWithPage({
    url: `${ApiRoutes.devices}?status=${DeviceStatusType.IN_ACTIVE}&suspend=false&page=${page}`
  });
  return response;
};

export const getDevicesListByName = async (name: string, clinicId?: number) => {
  const result = await get({
    url: `${ApiRoutes.devices}/search/?name=${name}&status=${DeviceStatusType.ACTIVE}&suspend=false&clinicId=${clinicId}`
  });
  return result;
};

export const getFullDevicesListByName = async (name: string, filter: string | null) => {
  const filterParam = getCorrectDeviceFilter(filter);
  const response = await getWithPage<IDevice[]>({
    url: `${ApiRoutes.devices}/search?name=${name}${filterParam}`
  });
  return response;
};

export const changeDeviceStatus = async (id: string, data: { suspend: boolean }) => {
  await put({ url: `${ApiRoutes.devices}/${id}`, body: data });
};

export const resetDevice = async (id: string) => {
  await post({ url: `${ApiRoutes.devices}/${id}/reset` });
};

export const createNewDevice = async (device: IAddDevice) => {
  await post({ url: `${ApiRoutes.devices}`, body: device });
};

export const deleteDevice = async (id: string) => {
  await deleteEntity({ url: `${ApiRoutes.devices}/${id}` });
};

const initialState: DevicesState = {
  devices: [],
  pagination: {
    page: 0,
    size: 15,
    total: 0,
    sorting: 'asc'
  },
  filter: null
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices: (state, { payload }) => {
      state.devices = payload;
    },
    setDevicesPage: (state, { payload }) => {
      state.pagination.page = payload;
    },
    setDevicesPageSize: (state, { payload }) => {
      state.pagination.size = payload;
    },
    setDevicesTotal: (state, { payload }) => {
      state.pagination.total = payload;
    },
    setDevicesFilter: (state, { payload }) => {
      state.filter = payload;
    },
    toggleDevicesSorting: (state) => {
      state.pagination.sorting = state.pagination.sorting === 'asc' ? 'desc' : 'asc';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDevicesList.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.devices = payload as IDevice[];
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const {
  setDevices,
  setDevicesPage,
  setDevicesPageSize,
  setDevicesTotal,
  setDevicesFilter,
  toggleDevicesSorting
} = devicesSlice.actions;

export default devicesSlice.reducer;
