import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import jwtDecode from 'jwt-decode';

import { persistor } from 'redux/store';
import { authUser, logoutUser } from 'services/auth/authService';
import { BaseUser, localStorageManager } from 'services/localStorage';
import { deleteEntity, get } from 'services/client';
import { AuthInfoType, IUser, LoginUserParams, UserState } from './types';
import { ApiRoutes } from 'constants/apiRoutes';
import { UserRoles } from 'redux/types';
import { setActiveMenuTab } from '../menu';
import { setSnackbar } from '../app';

export const fetchUserProfile = createAsyncThunk('user/getUserProfile', async (_, { dispatch }) => {
  const response = await get<IUser>({ url: ApiRoutes.profiles });
  dispatch(setUser(response));
});

export const deleteUserAccount = createAsyncThunk(
  'user/deleteUserAccount',
  async (_, { dispatch }) => {
    try {
      await deleteEntity({ url: ApiRoutes.profiles });
      localStorageManager.clearStorage();
      await persistor.purge();
      dispatch(removeUser());
    } catch (error: any) {
      if (error?.response?.data?.errorMessage === 'doctor_has_patients_violation') {
        dispatch(
          setSnackbar({
            message:
              'You cannot delete your account because you have patients. Please, assign them to another physician and try again',
            type: 'ERROR'
          })
        );
        return;
      }
    }
  }
);

const initialState: UserState = {
  user: null,
  auth: {}
};

export const logout = createAsyncThunk('user/logoutUser', async (_, { dispatch }) => {
  try {
    await logoutUser();
  } catch (error) {
    //
  } finally {
    await persistor.purge();
    dispatch(removeUser());
    localStorage.setItem('logged', '');
  }
});

export const loginUser = createAsyncThunk(
  'user/getUserInfo',
  async ({ data, onSuccess, onFail }: LoginUserParams, { dispatch }) => {
    try {
      const authInfo = (await authUser(data)) as AuthInfoType;

      if (authInfo.accessToken) {
        const { name, email, email_verified, family_name, given_name, realm_access, ...rest } =
          jwtDecode(authInfo.accessToken) as BaseUser;

        if (realm_access.roles?.includes(UserRoles.PlATFORM_ADMIN)) {
          dispatch(setActiveMenuTab('devices'));
        }

        onSuccess();
        dispatch(setUser({ name, email, email_verified, family_name, given_name, ...rest }));
        localStorageManager.setRefreshToken(authInfo.refreshToken);
        localStorageManager.setAccessToken(authInfo.accessToken);
        localStorageManager.setUser({
          name,
          email,
          email_verified,
          family_name,
          given_name
        });
        localStorageManager.setUserRole(realm_access.roles);
      }
      return authInfo;
    } catch (e) {
      onFail && onFail();
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    removeUser: (state) => {
      localStorageManager.clearStorage();
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
