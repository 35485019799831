import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

export enum LocalStorageKey {
  user = 'user',
  accessToken = 'access_token',
  refreshToken = 'refresh_token',
  userRole = 'roles'
}

export interface BaseUser {
  name: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  realm_access: { roles: string[] };
}
class LocalStorageManger {
  private getLocalStorageItem(key: LocalStorageKey): string | null {
    return localStorage.getItem(key);
  }

  private getComplexLocalStorageItem<T>(key: LocalStorageKey) {
    const obj = localStorage.getItem(key);
    return obj ? (JSON.parse(obj) as T) : null;
  }

  private setLocalStorageItem<T>(key: LocalStorageKey, entity: string | T): void {
    localStorage.setItem(key, typeof entity === 'string' ? entity : JSON.stringify(entity));
  }

  getUser(): Partial<BaseUser> | null {
    return this.getComplexLocalStorageItem<Partial<BaseUser>>(LocalStorageKey.user);
  }

  setUser(baseUser: Partial<BaseUser>): void {
    this.setLocalStorageItem<Partial<BaseUser>>(LocalStorageKey.user, baseUser);
  }

  getAccessToken(): string | null {
    return this.getLocalStorageItem(LocalStorageKey.accessToken);
  }

  setAccessToken(newToken: string): void {
    this.setLocalStorageItem(LocalStorageKey.accessToken, newToken);
  }

  getRefreshToken(): string | null {
    return this.getLocalStorageItem(LocalStorageKey.refreshToken);
  }

  setRefreshToken(newToken: string): void {
    this.setLocalStorageItem(LocalStorageKey.refreshToken, newToken);
  }

  clearTokens(): void {
    localStorage.removeItem(LocalStorageKey.accessToken);
    localStorage.removeItem(LocalStorageKey.refreshToken);
  }

  clearStorage(): void {
    localStorage.clear();
    ls.clear();
  }

  getUserRole(): string[] | null {
    return ls.get(LocalStorageKey.userRole);
  }

  setUserRole(role: string[]) {
    ls.set(LocalStorageKey.userRole, role);
  }
}

export const localStorageManager = new LocalStorageManger();
