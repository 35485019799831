import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { AppState } from './types';

const initialState: AppState = {
  snackbar: {
    open: false,
    message: '',
    type: 'ERROR'
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSnackbar: (state, { payload }) => {
      state.snackbar = {
        open: true,
        message: payload.message,
        type: payload.type
      };
    },
    resetSnackbar: (state) => {
      state.snackbar = {
        open: false,
        message: '',
        type: 'ERROR'
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export const { setSnackbar, resetSnackbar } = appSlice.actions;

export default appSlice.reducer;
