import { useMemo } from 'react';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from 'enums';
import { resetSnackbar } from 'redux/reducers/app';
import { AppDispatch } from 'redux/store';
import { whiteCrossIcon } from 'static/images';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { StyledSnackbar } from './Snackbar';
import { selectSnackbar } from 'redux/reducers/app/selectors';

export const Snackbar = () => {
  const { open, message, type } = useSelector(selectSnackbar);
  const dispatch = useDispatch<AppDispatch>();

  const color = useMemo(() => {
    if (type === 'SUCCESS') return Colors.success;
    if (type === 'INFO') return Colors.labels;

    return Colors.berry;
  }, [type]);

  const handleClose = () => {
    dispatch(resetSnackbar());
  };

  return (
    <StyledSnackbar
      color={color}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      message={
        <Text kind="bodySmall" color={Colors.white}>
          {message}
        </Text>
      }
      transitionDuration={{
        exit: 0,
        enter: 500
      }}
      onClose={handleClose}
      action={
        <IconButton size="small" onClick={handleClose}>
          <Icon src={whiteCrossIcon} width={16} height={16} />
        </IconButton>
      }
    />
  );
};
