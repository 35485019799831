import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from 'constants/apiRoutes';
import { PURGE } from 'redux-persist';

import { get } from 'services/client';
import { IScenario, ScenariosState } from './types';

export const fetchScenariosList = createAsyncThunk('devices/getList', async () => {
  const response = await get({ url: ApiRoutes.scenario });
  return response;
});

const initialState: ScenariosState = {
  scenarios: []
};

export const devicesSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScenariosList.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.scenarios = payload as IScenario[];
    });
    builder.addCase(PURGE, () => {
      return initialState;
    });
  }
});

export default devicesSlice.reducer;
