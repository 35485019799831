import { Base } from 'redux/types';
import { ITag } from '../scenarios/types';
import { UUID } from 'crypto';

export enum RehabilitationType {
  Stationary = 'STATIONARY',
  Aftercare = 'AFTERCARE'
}

export type IPatient = {
  doctorFirstName: string | null;
  doctorLastName: string | null;
  firstName: string;
  lastName: string;
  status: 'ACTIVE' | 'INACTIVE';
  age: number;
  clinicSummary: string;
  dateOfBirth: string;
  deviceId: string;
  deviceName: string;
  diagnoses: Array<{
    diagnoseCode: string;
    diagnoseName: string;
  }>;
  genderEnum: string;
  patientId: string;
  patientUuid: UUID;
  placingType: RehabilitationType;
  doctorId: string;
  idle: boolean;
};

export interface IDiagnose {
  diagnoseCode: string;
  diagnoseName: string;
}

// corresponds to SessionRespDTO in the backend (Java)
export interface ISession {
  amountOfExercises: number;
  completedAmountOfExercise: number;
  completedDate: string;
  sessionName: string;
  startDate: string;
  id: string;
  placingType: RehabilitationType;
}

// TODO: rename `ISessionExerciseDetail` to, for example, `ICompletedExercise`
/**
 * An object which descibes how well a patient did on _a single instance of an
 * exercise_ of the patient's choice (for example, watering the flowers in the
 * garden or pouring coffee into a mug) given a range of exercises from a larger
 * {@link ISessionExercise training scenario} (for exmaple, gardening or
 * housework).
 *
 * The details about each exercise completed (successfully or not) by a patient
 * *may* be used by a physician to study a patient's rehabilitation progress in
 * detail. However they are *always* used as the basis of a report sheet for a
 * patient which the health insurance providers need in order to reimburse the
 * physician, clinic and living brain for their services.
 */
export interface ISessionExerciseDetail {
  date: string;
  difficulty: number;
  mistakes: number;
  sessionsId: string;
  successfulFinish: boolean;
  timeSpent: number;
  placingTypeEnum: RehabilitationType;
}

// TODO: rename `ISessionExercise` to, for example, `ITrainingScenario`
/**
 * An object which describes a larger _training scenario_ (for example,
 * gardining) that a physician selected for inclusion in the individual training
 * plan of a patient (see `selectedPatientExercises` in {@link PatientsState}).
 *
 * As the patient progresses through their training plan, the `exercisesDetail`
 * list will become populated with details on how well the patient did on each
 * {@link ISessionExerciseDetail completed exercise} (for example, watering the
 * flowers).
 *
 * Note that the patient may choose to repeat the same exercise more than once,
 * in which case the same exercise can be reported as completed multiple times.
 */
export interface ISessionExercise {
  doneCount: number;
  exerciseId: number;
  exerciseName: string;
  // TODO: rename `exerciseDetail` to, for example, `completedExercises`
  exercisesDetail: ISessionExerciseDetail[];
  tags: ITag[];
}

export interface PatientsState {
  patients: IPatient[];
  personalPatients: IPatient[];
  archivedPatients: IPatient[];
  selectedPatient: IPatient | null;
  selectedPatientDetails: IPatient | null;
  selectedPatientPlan: string[];
  selectedPatientDevice: string;
  selectedPatientSession: ISession[];
  selectedUnCompletedSession: ISession[];

  /**
   * Data fetched from the API about the _currently selected_ patient's progress
   * towards their rehabilitation training plan that is both medically relevant
   * and relevant for the billing and cost reimbursement process between living
   * brain and the health insurance providers.
   */
  selectedPatientExercises: ISessionExercise[];

  selectedPatientSessionsAmount: number | null;
  isDeviceRequested: boolean;
  loading: boolean;
  diagnoses: IDiagnose[];
}

export interface IPatientData {
  age: number;
  clinicSummary: string | null;
  dateOfBirth: string;
  diagnoses: IDiagnose[];
  firstName: string;
  gender: string;
  lastName: string;
}

export interface IPatientUpdateData {
  clinicSummary: string;
  diagnoses: IDiagnose[];
  patientUuid: UUID;
  patientId: string;
  placingType: RehabilitationType;
}

export interface AddNewPatientParams extends Base {
  data: IPatientData;
  doctorId: string;
}

export interface ReassignOwnPatientParams extends Base {
  data: {
    doctorId: string;
    patients: string[];
  };
  doctorId: string;
}

export interface ReassignOthersPatientParams extends Base {
  data: {
    toDoctorId: string;
    patients: string[];
    fromDoctorId: string;
  };
  doctorId: string;
}

export interface AddStationaryPlanParams extends Base {
  data: StationaryPlan;
  id: UUID;
}

export interface AddAftercarePlanParams extends Base {
  data: StationaryPlan;
  id: UUID;
}

export interface UpdateStationaryPlanParams extends Base {
  data: { exercises: number[] };
  id: UUID;
}

export interface ISessionUpdate {
  exercises: string[];
  amountOfSession: number;
}

export interface UpdateAftercarePlanParams extends Base {
  data: ISessionUpdate;
  patientUuid: UUID;
}

export interface DeletePatientParams extends Base {
  id: UUID;
  doctorId: string;
}

export interface GetPatientSessionParams {
  id: UUID;
}

export interface StationaryPlan {
  deviceId: string;
  exercises: number[];
  session: number;
  type: RehabilitationType;
  clinicAddress: string;
  clinicName: string;
}
