import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { logout } from 'redux/reducers/user';
import { store } from 'redux/store';
import { localStorageManager } from './localStorage';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '/'
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if (config.url.includes('login')) return;

    if (
      config.url.includes('refresh') &&
      (error.response.status === 400 || error.response.status === 500)
    ) {
      store.dispatch(logout());
    }

    if (error.response.status === 401 && !config._retry) {
      config._retry = true;

      try {
        const rs: any = await refreshToken();
        const { accessToken, refreshToken: refresh_token } = rs;
        localStorageManager.setAccessToken(accessToken);
        localStorageManager.setRefreshToken(refresh_token);

        return client({
          ...error?.response?.config,
          headers: {
            ...error?.response?.config?.headers,
            authorization: `Bearer ${accessToken}`
          }
        });
      } catch (err: any) {
        if (err.response && err.response.data) {
          return Promise.reject(err.response.data);
        }

        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

client.interceptors.request.use(
  async (config) => {
    if (config.url?.includes('login')) return config;

    const isAuthType = config.url?.includes('logout') || config.url?.includes('refresh');

    const authToken = localStorageManager.getAccessToken();
    const refreshToken = localStorageManager.getRefreshToken();

    if (authToken || (isAuthType && refreshToken)) {
      config.headers = {
        ...config.headers,
        authorization: isAuthType ? refreshToken : `Bearer ${authToken}`
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

interface RequestParamsType {
  url: string;
  responseType?: ResponseType;
  params?: AxiosRequestConfig;
  headers?: AxiosRequestHeaders;
}

interface RequestWithBodyType extends RequestParamsType {
  body?: unknown;
  headers?: AxiosRequestHeaders;
}

interface IResponse {
  data: { data: any };
}

export interface IResponseWithPage<T> {
  data: T;
  totalPages: number;
  totalElements: number;
  currentPage: number;
}

const refreshToken = () => {
  return post({ url: '/api/auth/refresh' });
};

export const post = <T>({ url, body, headers }: RequestWithBodyType): Promise<T> => {
  return client.post(url, body, { headers }).then(({ data }: IResponse) => {
    return data as T;
  });
};

export const patch = <T>({ url, body }: RequestWithBodyType): Promise<T> =>
  client.patch(url, body).then(({ data }: IResponse) => data as T);

export const put = <T>({ url, body }: RequestWithBodyType): Promise<T> =>
  client.put(url, body).then(({ data }: IResponse) => data as T);

export const get = <T>({ url, params }: RequestParamsType): Promise<T> => {
  return client.get(url, { params }).then((response: IResponse) => {
    if ('data' in response.data) {
      return response.data.data as T;
    } else {
      return response.data as T;
    }
  });
};

export const getWithPage = <T>({
  url,
  params
}: RequestParamsType): Promise<IResponseWithPage<T>> => {
  return client.get(url, { params }).then((response: AxiosResponse<IResponseWithPage<T>>) => {
    return response.data as IResponseWithPage<T>;
  });
};

export const deleteEntity = <T>({ url, data = {} }: { url: string; data?: any }): Promise<T> => {
  return client.delete(url, { data }).then(({ data }: IResponse) => data as T);
};

export default client;

// client.get (https://www.freecodecamp.org/news/how-to-use-axios-with-react/#how-to-use-the-async-await-syntax-with-axios)
