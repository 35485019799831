import { ITheme } from '../interfaces/styled';
import { Colors, Media } from '../enums';

export const baseTheme: ITheme = {
  colors: {
    tagBlue: Colors.tagBlue,
    tagGreen: Colors.tagGreen,
    mainText: Colors.mainText,
    lineHover: Colors.lineHover,
    lightViolet: Colors.lightViolet,
    violet: Colors.violet,
    actionViolet: Colors.actionViolet,
    berry: Colors.berry,
    alert: Colors.alert,
    background: Colors.background,
    borders: Colors.borders,
    disabled: Colors.disabled,
    labels: Colors.labels,
    darkGrey: Colors.darkGrey,
    success: Colors.success,
    white: Colors.white,
    profileBg: Colors.profileBG,
    black: Colors.black
  },

  media: {
    mobile: Media.mobile,
    tablet: Media.tablet,
    notebook: Media.notebook,
    desktop: Media.desktop
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '41px'
    },
    h2: {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '150%'
    },
    h3: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '27px'
    },
    h4: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '150%'
    },
    button: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '26px'
    },
    buttonSmall: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '23px'
    },
    body: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '27px'
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px'
    },
    caption: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%'
    }
  }
};
